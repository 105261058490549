<template>
  <a-modal
    title="添加/编辑分类"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="saveDataApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="分类名称"
        :validateStatus="checkParams.titleStatus"
        :help="checkParams.titleStatusMsg"
      >
        <a-input
          placeholder="请输入分类名称"
          v-decorator="['title', {initialValue: queryParam.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { gridSaveClassify } from '@/api/gridevent'
export default {
  name: 'ClassifyInfo',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      missionStatus: 0,
      checkParams: {
        titleStatus: 'success',
        titleStatusMsg: null
      },
      queryParam: {
        id: 0,
        title: ''
      }
    }
  },
  created () {
  },
  methods: {
    titleCheck (rule, value, callback) {
      const _this = this
      _this.queryParam.title = value.trim()
      if (_this.queryParam.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入分类名称'
        callback(new Error('请输入分类名称'))
      }
    },
    ininData (data) {
      console.log('ininData', data)
      this.checkParams.titleStatus = 'success'
      this.checkParams.titleStatusMsg = null
      this.queryParam.title = data.title
      this.queryParam.id = data.id
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    saveDataApi () {
      const _this = this
      if (_this.queryParam.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入分类名称'
        return false
      }
      _this.confirmLoading = true
      gridSaveClassify(_this.queryParam).then((res) => {
        _this.confirmLoading = false
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$emit('ok', 1)
          _this.cancelForm()
        } else if (res.errorCode === 9400007) {
          _this.checkParams.titleStatus = 'error'
          _this.checkParams.titleStatusMsg = res.errorMsg
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
