<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="分类名称">
                <a-input placeholder="请输入分类名称" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="gotoAddInfo()">添加</a-button>
        <a-button type="primary" icon="plus" @click="gotoAssignClassify()">分配网格员</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="gotoInfo(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要删除此问题分类吗？" @confirm="delClassifyApi(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </s-table>
      <classify-info @ok="handleOk" ref="classifyinfo"></classify-info>
      <assign-classify ref="assignclassify"></assign-classify>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import ClassifyInfo from '@/views/grid/components/ClassifyInfo'
import AssignClassify from '@/views/grid/components/AssignClassify'
import { gridClassifyList, gridDelClassify } from '@/api/gridevent'
const columns = [
  {
    title: '编号',
    dataIndex: 'id',
    width: '100px'
  },
  {
    title: '分类名称',
    dataIndex: 'title'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '200px'
  },
  {
    title: '操作',
    width: '150px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ClassifyList',
  components: {
    STable,
    Ellipsis,
    STree,
    ClassifyInfo,
    AssignClassify
  },
  data () {
    this.columns = columns
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        title: '',
        status: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return gridClassifyList(requestParameters)
          .then(res => {
            return res.result
          })
      }
    }
  },
  filters: {
  },
  created () {
  },
  watch: {
    $route (val) {
      if (val.name === 'CommunityActivitiesList') {
        this.queryParam = {
          title: '',
          status: 0
        }
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        title: '',
        status: 0
      }
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    delClassifyApi (id) {
      console.log(id)
      const _this = this
      gridDelClassify({ id: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gotoInfo (data) {
      this.$refs.classifyinfo.ininData(data)
    },
    gotoAddInfo () {
      var info = {
        id: 0,
        title: ''
      }
      this.$refs.classifyinfo.ininData(info)
    },
    gotoAssignClassify () {
      var info = {
        userId: undefined,
        userCenterId: 0,
        classifyIdArr: []
      }
      this.$refs.assignclassify.ininData(info)
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    }
  }
}
</script>
